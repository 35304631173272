<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>
    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/homePage/合作与发展banner.png" class="images">
      </el-carousel-item>
    </el-carousel>
    <!-- 内容 -->
    <div class="content">

      <div class="content_1">
        <div class="content_2" v-for="item in imgList" :key="item.id" @click="Btn(item.id)">
            <img :src="item.url" alt="">
            <p class="p_1">
             
            </p>
            <div class="p_2">
                <h2 class="H_1">{{item.name}}</h2>
                <p class="p_3">{{item.title_1}}</p>
                <p class="">{{item.title_2}}</p>
            </div>
        </div>
      </div>
      <!-- <img class="images_2" src="@/assets/cooperation/5.png" alt=""> -->
    </div>
    <!-- 底部 -->
    <foot style="margin-top: 10rem;"></foot>
  </div>
  <div class="_div">
    <move_header></move_header>
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
          <img src="@/assets/homePage/合作与发展banner.png" class="images">
      </van-swipe-item>
    </van-swipe>
      <div class="content">
          <div class="content_1" v-for="item in imgList" :key="item.id" @click="Btn(item.id)">
                <img :src="item.url" alt="">
                <div class="floT">
                </div>
                <div class="floT_1">
                    <h3>{{item.name}}</h3>
                    <p>{{item.title_1}}</p>
                </div>
          </div>
      </div>
    <move_foot></move_foot>
  </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_header from '../move_components/move_header.vue'
import move_foot from '../move_components/move_foot.vue'
import { useRouter } from "vue-router";
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
export default {
  components: { foot, headers, move_header, move_foot },
  setup() {
    const router = useRouter()
    const state = reactive({
      imgList: [
        { id: 1,name:'可持续发展',title_1:'绿色环保、和谐生态',title_2:'', url: require('@/assets/cooperation/1.png') },
        { id: 2,name:'商业合作伙伴',title_1:'本分、拼搏、分享、创造',title_2:'', url: require('@/assets/cooperation/2.png') },
        { id: 3,name:'企业社会责任',title_1:'做强、走远、永续发展',title_2:'', url: require('@/assets/cooperation/3.png') },
        { id: 4,name:'商业道德',title_1:'发展始于诚信、共赢创造价值',title_2:'', url: require('@/assets/cooperation/4.png') },
      ]
    })
    let methods = {
      Btn(id){
        switch (id) {
          case 1:
            router.push('/zh/develop')
            break;
          case 2:
            router.push('/zh/business_partner')
            break;
          case 3:
            router.push('/zh/social_responsibility')
            break;
          case 4:
            router.push('/zh/commercial_ethics')
            break;
        
          default:
            break;
        }
      }
    }
    onMounted(()=>{
      let p = document.getElementsByClassName('p_1')
      let p_2 = document.getElementsByClassName('p_2')
      let H_1 = document.getElementsByClassName('H_1')
      let floT = document.getElementsByClassName('floT')
      // console.log(H_1);
    for(let i=0;i<p.length;i++){
      if(i%2!=0){
        p[i].style.right = 0
        p[i].style.left = '1.9rem'
        p_2[i].style.right = 0
        p_2[i].style.left = '5.9rem'
      }
      if(i==0){
        p[i].style.backgroundColor = '#126ab2'
        floT[i].style.backgroundColor = '#126ab2'
      }else if(i==1){
        p[i].style.backgroundColor = '#a2a2a2'
        floT[i].style.backgroundColor = '#a2a2a2'

      }else if(i==2){
        p[i].style.backgroundColor = '#47bc7a'
        floT[i].style.backgroundColor = '#47bc7a'

      }else if(i==3){
        p[i].style.backgroundColor = '#986e3c'
        floT[i].style.backgroundColor = '#986e3c'
        // H_1[i].style.marginLeft = '0px'
        // H_1[i].style.marginBottom = '-20px'

        
      }
    }
    })
    
    return {
      ...toRefs(state),...methods
    }
  }
};
</script>

<style scoped lang="less">
.box {
  width: 100%;

  .content {
    max-width: 1920px;
    margin: 50px auto;
    width: 70%;
  position: relative;
  .content_1{
    width: 100%;
    
    .content_2{
      cursor: pointer;
      display: inline-block;
      box-sizing: border-box;
      padding: 30px;
      width: 48%;
      position: relative;
     
      img{
        height: 420px;
        width: 100%;
      }
      .p_1:nth-child(2){
        right: 30px;bottom: 50px;
        
        position: absolute;
        width: 300px;
        height: 240px;
        box-sizing: border-box;
        padding-top: 70px;
        // line-height: 240px;
        text-align: center;
        z-index: 9;
        color: rgb(38, 70, 230);
        opacity: 0.7;
    
      }
      .p_2{
        position: absolute;
        right: 90px;bottom: 140px;
        color: #fff;
        z-index: 9;
       
        h2{box-sizing: border-box;width: 200px;text-align: center;}
        p{
          width: 210px;
          text-align: center;
        }
      }
        
    }

    
  }
  .images_2{
    position: absolute;
    z-index: -1;
    bottom: -6rem;
    padding: 30px;
    box-sizing: border-box;
    width: 100%;
  }
  }
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

.images {
  width: 100%;
  height: auto;
  max-height: 600px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;
    .content{
      padding: 30px;
      .content_1{
        position: relative;
        width: 100%;
        margin: 50px 0;
        cursor: pointer;
        img{width: 100%;}
        .floT{
          position: absolute;
          text-align: center;
          bottom:5px;
          width: 100%;
          height: 300px;
          box-sizing: border-box;
          padding-top: 0px;
           opacity: 0.7;
          color: #fff;
      
        }
          .floT_1{
                     position: absolute;
          text-align: center;
          bottom:5px;
          width: 100%;
          height: 300px;
          box-sizing: border-box;
          padding-top: 0px;
          color: #fff;
              h3{
            font-size: 60px;
          }
          p{font-size: 45px;}
          }
      }
    }
  }
}
</style>